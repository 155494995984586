<template>
  <div class="forgot-password">
    <div class="forgot-password-left">
      <router-link :to="`${'/' + this.GLOBAL.currentLanguage + '/'}`">
        <img alt="" src="../assets/image/logo.svg" class="logo" />
      </router-link>
      <img
        alt=""
        src="../assets/image/forgot_password_bg.svg"
        class="forgot-password-bg"
      />
    </div>
    <div class="forgot-password-right">
      <!--send email-->
      <div class="forgot-password-box" v-show="showEmailBox">
        <div class="forgot-password-title">
          {{ $t("forget_password_reset_password") }}
        </div>
        <div class="forgot-password-tip">
          {{ $t("forget_password_subtitle") }}
        </div>
        <div class="input-box input-email-space">
          <div
            class="email-address-tip"
            v-if="email"
            :style="{ color: isEmailValid ? '#4EBE86' : '#F26D76' }"
          >
            {{ $t("forget_password_email") }}
          </div>
          <el-input
            class="input-email"
            v-model="email"
            :placeholder="$t('forget_password_email')"
          ></el-input>
        </div>
        <div class="email-error-tip" v-show="showEmailErrorTip">
          This user does not exist. Please make sure you enter your email
          address correctly.
        </div>
        <button
          type="button"
          class="
            forgot-password-btn
            btn-size-format-2 btn-radius-format-1 btn-color-1 btn-cursor
          "
          @click="send_reset_email"
        >
          <span>{{ $t("forget_password_next") }}</span>
        </button>
        <div class="sign-in-btn btn-cursor">
          <router-link :to="`${'/' + this.GLOBAL.currentLanguage + '/login'}`"
            >{{ $t("forget_password_sign_in") }}
          </router-link>
        </div>
      </div>
      <!--send email success-->
      <div class="send-email-success-box" v-show="showEmailSendSuccess">
        <div class="send-email-success-title">
          {{ $t("forget_password_reset_password") }}
        </div>
        <div class="send-email-success-tip">
          {{ $t("forget_password_email_send_success") }}
        </div>
        <div class="send-email-success-image">
          <img alt="" src="../assets/image/forgot_password_email_success.svg" />
        </div>
        <router-link :to="`${'/' + this.GLOBAL.currentLanguage + '/login'}`">
          <button
            type="button"
            class="
              back-login-btn
              btn-size-format-2 btn-radius-format-1 btn-color-1 btn-cursor
            "
          >
            <span>{{ $t("forget_password_back_to_login") }}</span>
          </button>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ResetPassword",
  data() {
    return {
      showEmailBox: true,
      showEmailErrorTip: false,
      showEmailSendSuccess: false,
      email: null,
      isEmailValid: true,
    };
  },
  methods: {
    send_reset_email() {
      if (this.email === null) {
        this.$message.error("please input email");
      } else {
        this.$axios
          .post("/mail/reset-password", {
            email: this.email,
            webDomain: this.GLOBAL.getWebDomain(),
          })
          .then(() => {
            this.showEmailBox = false;
            this.showEmailSendSuccess = true;
          });
      }
    },
  },
  created() {},
  mounted() {},
};
</script>

<style scoped>
.forgot-password {
  min-height: 100vh;
  min-width: 100vw;
  background-color: #f8f8f8;
  display: flex;
  justify-content: center;
}

.forgot-password-left {
  width: 100%;
  max-width: 1167px;
  display: flex;
  flex-direction: column;
  background-color: #f8f8f8;
}

@media screen and (max-width: 1024px) {
  .forgot-password-left {
    display: none;
  }
}

.logo {
  margin-top: 43px;
  margin-left: 114px;
}

.forgot-password-bg {
  margin-top: 103px;
  max-width: 80%;
  align-self: center;
}

.forgot-password-right {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ffffff;
}

.forgot-password-box {
  padding: 175px 16px;
}

.forgot-password-title {
  font-size: 40px;
  font-weight: 400;
  line-height: 49px;
  color: #161823;
}

.forgot-password-tip {
  max-width: 360px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #161823;
  margin-top: 10px;
}

.input-box {
  position: relative;
  height: 60px;
  background: #ffffff;
  border: 1px solid #e1e1e1;
  border-radius: 5px;
}

.input-email-space {
  margin-top: 40px;
}

.input-email {
  height: 19px;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: #141923;
  opacity: 0.8;
}

.email-error-tip {
  max-width: 360px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #f26d76;
  margin-top: 10px;
}

.forgot-password-btn {
  width: 100%;
  margin-top: 24px;
}

.sign-in-btn {
  margin-top: 20px;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: rgba(20, 25, 35, 0.6);
  opacity: 0.8;
  text-align: right;
}

.send-email-success-box {
  margin: 175px 16px;
}

.send-email-success-title {
  font-size: 40px;
  font-weight: 400;
  line-height: 49px;
  color: #161823;
}

.send-email-success-tip {
  max-width: 359px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #161823;
  margin-top: 10px;
}

.send-email-success-image {
  margin-top: 10px;
  text-align: center;
}

.send-email-success-image img {
  max-width: 100%;
  height: auto;
}

.back-login-btn {
  width: 100%;
}

.email-address-tip {
  position: absolute;
  left: 15px;
  top: 4px;
  height: 13px;
  font-size: 12px;
  font-weight: 400;
  line-height: 13px;
  color: #4ebe86;
  opacity: 0.8;
  z-index: 1;
}
</style>
